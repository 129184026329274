import React from 'react';
import * as styles from './services.module.css';

import Container from '../../components/Container';
import Layout from '../../components/Layout';
import BeforeAfter from '../../components/BeforeAfter';

const InGroundPage = () => {
  return (<Layout disablePaddingBottom>
    <div className={styles.root}>
        <Container>
            <div data-fade-in-section className={styles.gridLayout}>
                <h1>Inground Vinyl Pool Installation</h1>
                <p>
                    Vinyl-lined pools are the most common type of swimming pool in the United States. While they are
                    ubiquitous in colder-climate states, they are also installed throughout California and are the most
                    budget-friendly option for inground pools.
                    Compared to concrete and fiberglass pools, inground vinyl pools have numerous advantages.
                </p>
                <div className={`${styles.spacer30} ${styles.gridSpan2}`}></div>
                <img className={styles.image} height={380} src="/pool_reline_catterson.jpeg"></img>
                <img className={styles.image} height={480} src="/pool_reline_pennington.jpeg"></img>
            </div>
            <div className={styles.gridLayout}>
                <h2 data-fade-in-section className={styles.gridSpan2}>Vinyl Pool Advantages</h2>
                <div data-fade-in-section>
                    <b>Cost-Effective</b>
                    <p>                   
                        There are three basic types of inground pools: concrete (or gunite), fiberglass and vinyl. While each has
                        its advantages and drawbacks, vinyl liner pools are without a doubt the most economical choice. The
                        initial installation is considerably more affordable compared to a gunite or fiberglass pool of a similar
                        size and shape.
                        <br></br>
                        <br></br>
                        All pools eventually require resurfacing. While vinyl liners need to be replaced every 10-15 years, it is far
                        less expensive to reline compared to resurfacing a fiberglass or gunite pool.
                        Vinyl pools are also less expensive to repair and pool owners will save on maintenance costs.
                    </p>
                </div>
                <div data-fade-in-section>
                    <b>Lower Maintenance</b>
                    <p>
                        While all pools require maintenance, vinyl liner pools require less chemicals compared to gunite pools
                        because they have a nonporous surface and are not a cementitious material. Their smooth surface
                        makes it more difficult for any type of algae to take hold. Vinyl pools are easier to brush and allow
                        automatic vacuums to perform well.
                    </p>
                </div>
                <div data-fade-in-section>
                    <b>Endless Design Options</b>
                    <p>
                        The design possibilities for inground vinyl pools are practically limitless. Modern manufacturing
                        techniques can produce a vinyl liner for virtually any size or shape of pool. For smaller backyards, vinyl
                        pools are a great option as they can be designed to maximize space while meeting setback
                        requirements. Vinyl pools can also be designed with more depth than fiberglass options.
                        <br></br>
                        <br></br>
                        Also, the look of your backyard is more customizable with vinyl pools because liners come in a myriad of
                        different color and pattern options. When it comes time to replace the liner, you will have the
                        opportunity to create an entirely new look for your pool and backyard.
                    </p>
                </div>
                <div data-fade-in-section>
                    <b>Smooth Surface</b>
                    <p>
                        One of the most appealing benefits of a vinyl liner pool is its smooth surface. Over time, the plaster on
                        gunite pools wears thin, causing an etched, rough surface. As the gel coat of a fiberglass pool is worn,
                        the pool surface may become chalky and exposed fiberglass can be very itchy and uncomfortable to the
                        touch. A vinyl-lined pool will never have these issues. Its surface will always remain safe and smooth.
                    </p>
                </div> 
            </div>
        </Container>
        <Container>
            <section className={styles.imagesSection}>
                <img className={styles.image} height={420} src="/inground_1.jpeg"></img>
                <img className={styles.image} height={360} src="/inground_3.jpeg"></img>
                <img className={styles.image} height={360} src="/pool_reline_inground.png"></img>
            </section>
        </Container>
    </div>
  </Layout>);
};

export default InGroundPage;